<template>
  <div>
    <b-card title="Görsel">
      <b-img
        v-if="dataItem.image"
        :src="imageUrl + '/media/certificates/' + dataItem.image"
        thumbnail
        center
      />
      <div class="text-center mt-1">
        <b-button
          variant="primary"
          @click="setModal('certificates')"
        >
          <FeatherIcon icon="UploadIcon" />
          Görsel Yükle
        </b-button>
      </div>
    </b-card>
    <cropper-image
      v-if="cropperSettings.type"
      :modal-status="cropperSettings.modalStatus"
      :close-modal="closeModal"
      :file-name="cropperSettings.fileName"
      :folder="cropperSettings.folder"
      :ratio="cropperSettings.ratio"
      @uploadImage="setUploadImage"
    />
  </div>
</template>
<script>
import { BCard, BImg, BButton } from 'bootstrap-vue'
import CropperImage from '@/layouts/components/common/CropperImage.vue'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'ImageCard',
  components: {
    BCard,
    BImg,
    BButton,
    CropperImage,
  },
  data() {
    return {
      cropperSettings: {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      },
      required,
      imageUrl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['certificates/dataItem']
    },
  },
  methods: {
    setModal(type) {
      if (type === 'certificates') {
        this.cropperSettings = {
          modalStatus: true,
          fileName: 'certificates-image.jpg',
          folder: 'certificates',
          ratio: 1 / 1.4142,
          type: 'certificates',
        }
      }
    },
    closeModal() {
      this.cropperSettings = {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      }
    },
    setUploadImage(item) {
      this.dataItem.image = item.image
      this.dataItem.thumb = item.thumb
      this.cropperSettings.modalStatus = false
    },
  },
}
</script>
